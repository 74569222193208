<template>
  <toolbar title="Kontrollgang" :backUrl="'/job/' + tour.job + '/tours'" />
  <div
    v-if="scanError"
    class="fixed top-10 center p-10 bg-red-500 rounded-lg text-center text-3xl font-bold text-black"
  >
    {{ scanError }}
  </div>
  <div
    v-if="scanSuccess"
    class="fixed top-10 center p-10 bg-green-500 rounded-lg text-center text-3xl font-bold text-black"
  >
    {{ scanSuccess }}
  </div>

  <div v-show="qrscan" class="absolute w-full h-full top-0 left-0 z-10">
    <div class="text-center pt-36 w-full h-full z-10 bg-black/80">
      <video
        id="video"
        ref="video"
        class="w-96 h-96 max-h-96 inline-block"
        autoplay
      ></video
      ><br />
      <button class="text-black bg-red-500" @click="stopQRScan()">
        Abbrechen
      </button>
    </div>
  </div>
  <div v-if="stats.currentJob" class="md:p-4">
    <div class="bg-white rounded-lg p-4">
      <span class="text-2xl font-bold inline-block mr-4"
        >Route: {{ route.name }}</span
      >
      <div class="grid grid-cols-2 font-bold mt-4">
        <div>
          <label class="mr-4">Einsatzort:</label>
          <input
            type="text"
            v-model="stats.currentJob.Einsatzort_Beschreibung"
            disabled
          />
        </div>
        <div>
          <label class="mr-4">Einsatzstelle:</label>
          <input
            type="text"
            v-model="stats.currentJob.Einsatzstelle_Beschreibung"
            disabled
          />
        </div>
        <div>
          <label class="mr-4">Mitarbeiter:</label>
          <input
            type="text"
            v-model="stats.currentJob.Mitarbeitername"
            disabled
          />
        </div>
        <div>
          <label class="mr-4">Datum:</label>
          <input type="date" v-model="tour.date" disabled />
        </div>
        <div>
          <label class="mr-4">Start:</label>
          <input type="time" v-model="tour.startedAt" disabled />
        </div>
        <div>
          <label class="mr-4">Ende</label>
          <input type="time" v-model="tour.finishedAt" disabled />
        </div>
      </div>
      <div v-if="!tour.startedAt" class="mt-4">
        <button @click="startTour()">Rundgang starten</button>
      </div>
    </div>
    <div v-if="tour.startedAt" class="">
      <span class="text-xl font-bold">Wegpunkt erfassen</span>
      <div class="grid grid-cols-1 mt-2">
        <div class="text-center">
          <button class="inline-block" @click="startQRScan()">
            <i class="fas fa-qrcode fa-2x" /><span
              class="align-top mt-1 inline-block ml-2"
              >QR-Code</span
            >
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="tour.startedAt && route.waypoints"
      class="w-full overflow-x-auto"
    >
      <table>
        <thead>
          <th>Name</th>
          <th>Beschriftung</th>
          <th>Status</th>
          <th>Kommentar</th>
        </thead>
        <tbody>
          <tr
            v-for="waypoint in route.waypoints"
            :key="waypoint.name"
            class="border-y p-2 cursor-pointer"
          >
            <td>{{ waypoint.name }}</td>
            <td>{{ waypoint.code }}</td>
            <td>
              <div
                v-if="!waypoint.scanned"
                class="text-sm font-bold inline-block p-1 rounded ml-2 border text-red-800 bg-red-200 border-red-800"
              >
                Offen
              </div>
              <div
                v-else
                class="text-sm font-bold inline-block p-1 rounded ml-2 border text-green-800 bg-green-200 border-green-800"
              >
                {{ waypoint.scanned.scannedAt }}
              </div>
            </td>
            <td v-if="waypoint.scanned">
              <textarea
                class="p-2"
                @input="editedComment = waypoint"
                v-model="waypoint.scanned.comment"
              ></textarea
              ><button
                v-if="editedComment == waypoint"
                @click="saveComment(waypoint.scanned)"
              >
                Speichern
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar.vue";
import CASP from "../../CASP";
export default {
  name: "Tour",
  components: {
    toolbar,
  },
  data() {
    return {
      scanSuccess: false,
      scanError: false,
      qrscan: false,
      window: window,
      tour: {
        waypointscans: [],
      },
      waypointscans: [],
      editedComment: false,
      route: {},
      online: false,
      stats: {},
      local: {},
    };
  },
  methods: {
    saveComment(waypointscan) {
      this.editedComment = false;
      if (this.online) {
        CASP.updateWaypointscan(
          waypointscan.id,
          waypointscan,
          (status, response) => {
            if (status == 200) {
              this.waypointscans[
                this.waypointscans.findIndex((t) => t.id == waypointscan.id)
              ] = response;
              this.tour.waypointscans[
                this.tour.waypointscans.findIndex(
                  (t) => t.id == waypointscan.id
                )
              ] = response;
              this.updateStats();
            } else if (status == 0) {
              this.saveLocally(response, "waypointscans");
            }
          }
        );
      } else {
        this.saveLocally(waypointscan, "waypointscans");
      }
    },
    startTour() {
      if (!this.tour.id) {
        this.tour.id = "tmp_" + new Date().getTime();
        this.tour.startedAt = new Date().toLocaleTimeString();
        this.tour.status = "In Arbeit";
      }
      if (this.online) {
        CASP.addTour(this.route.id, this.tour, (status, response) => {
          if (status == 200) {
            this.saveAndRedirect(response);
          } else if (status == 0) {
            this.saveLocally(this.tour, "tours");
            window.location = "/tours/" + this.tour.id;
          }
        });
      } else {
        this.saveLocally(this.tour, "tours");
        window.location = "/tours/" + this.tour.id;
      }
    },
    saveAndRedirect(tour) {
      this.tour = tour;
      this.updateStats();
      window.location = "/tours/" + this.tour.id;
    },
    stopQRScan() {
      if (this.qrscan) {
        window.rescanner.stopQRScan();
        this.qrscan = false;
      }
    },
    updateStats() {
      let oldTourIndex = this.stats.currentJob.tours.findIndex(
        (t) => t.id == this.tour.id
      );
      if (oldTourIndex !== -1) {
        this.stats.currentJob.tours[oldTourIndex] = this.tour;
      } else {
        this.stats.currentJob.tours.push(this.tour);
      }
      localStorage.setItem("stats", JSON.stringify(this.stats));
    },
    startQRScan() {
      if (this.qrscan) {
        window.rescanner.stopQRScan();
        this.qrscan = false;
        return;
      }
      console.log("start scan");
      window.rescanner.startQRScan(this.$refs.video, "environment");
      this.qrscan = true;
    },
    scanResult(message) {
      this.stopQRScan();
      console.log(message);
      // find waypoint where id is message
      let waypoint = this.route.waypoints.find((w) => w.code == message);
      if (waypoint) {
        this.saveWaypointscan(waypoint);
      } else {
        this.scanError = "Code gehört zu keinem Wegpunkt!";
        setTimeout(() => {
          this.scanError = false;
        }, 3000);
      }
    },

    checkIfWaypointsAreScanned() {
      console.log(this.waypointscans)
      for (let i = 0; i < this.route.waypoints.length; i++) {
        this.route.waypoints[i].scanned = this.waypointscans.reverse().find(
          (w) => w.waypoint == this.route.waypoints[i].id
        );
      }
    },
    saveLocally(data, type) {
      if (!this.local[type]) this.local[type] = [];
      this.local[type].push(data);
      localStorage.setItem("local", JSON.stringify(this.local));
    },
    async asyncAddTour(routeId, tour) {
      return new Promise((resolve) => {
        CASP.addTour(routeId, tour, (status, response) => {
          resolve({ status, response });
        });
      });
    },
    async asyncSaveWaypointscan(scan) {
      if (scan.id) {
        return new Promise((resolve) => {
          CASP.updateWaypointscan(scan.id, scan, (status, response) => {
            resolve({ status, response });
          });
        });
      } else {
        return new Promise((resolve) => {
          CASP.addWaypointscan(scan.tour, scan, (status, response) => {
            resolve({ status, response });
          });
        });
      }
    },
    async tryToSyncLocalUpdates() {
      if (!this.online) return;
      if (this.local.tours) {
        for (let i = 0; i < this.local.tours.length; i++) {
          let tour = this.local.tours[i];
          let { status, response } = await this.asyncAddTour(
            this.route.id,
            this.tour
          );
          if (status == 200) {
            console.log(response);
            this.local.tours.splice(i, 1);
            if (tour.id == this.tour.id) {
              this.tour = response;
            }
            if (this.local.waypointscans && tour.id != response.id) {
              this.local.waypointscans.forEach((waypoint, index) => {
                if (waypoint.tour === tour.id) {
                  console.log(
                    "Changing id from " + waypoint.tour + " to " + response.id
                  );
                  this.local.waypointscans[index].tour = response.id;
                }
              });
            }
            localStorage.setItem("local", JSON.stringify(this.local));
            if (tour.id != response.id) {
              window.location = "/tours/" + response.id;
            }
          }
        }
      }
      if (this.local.waypointscans) {
        for (let i = 0; i < this.local.waypointscans.length; i++) {
          let waypoint = this.local.waypointscans[i];
          let { status, response } = await this.asyncSaveWaypointscan(
            waypoint
          );
          if (status == 200) {
            this.local.waypointscans.splice(i, 1);
            this.tour.waypointscans.push(response);
            this.waypointscans.push(response);
            localStorage.setItem("local", JSON.stringify(this.local));
            this.updateStats();
          }
        }
      }
    },
    saveWaypointscan(waypoint) {
      if (waypoint.scanned) {
        this.scanError = "Wegpunkt bereits gescannt!";
        setTimeout(() => {
          this.scanError = false;
        }, 3000);
        return;
      }
      this.scanSuccess = "Wegpunkt erfolgreich gescannt!";
        setTimeout(() => {
          this.scanSuccess = false;
        }, 3000);
      waypoint.scanned = {
        scannedAt: new Date().toLocaleTimeString(),
        waypoint: waypoint.id,
        tour: this.tour.id,
        comment: "",
      };
      if (this.online) {
        CASP.addWaypointscan(
          this.tour.id,
          waypoint.scanned,
          (status, response) => {
            if (status == 200) {
              waypoint.scanned = response;
              this.tour.waypointscans.push(response);
              this.waypointscans.push(response);
              this.updateStats();
            } else if (status == 0) {
              this.waypointscans.push(waypoint.scanned);
              this.saveLocally(waypoint.scanned, "waypointscans");
            }
          }
        );
      } else {
        this.waypointscans.push(waypoint.scanned);
        this.saveLocally(waypoint.scanned, "waypointscans");
      }
    },
  },
  created: function () {
    let vm = this;

    document.getElementById("mainspinner").style.display = "none";
    // Check online Status
    CASP.isOnline((online) => {
      this.online = online;
    });
    setInterval(() => {
      CASP.isOnline((online) => {
        if (this.online != online) {
          this.online = online;
          this.tryToSyncLocalUpdates();
        }
      });
    }, 10000);

    // Try to retrieve latest tour details from localstorage
    this.stats = JSON.parse(localStorage.getItem("stats"));
    this.local = localStorage.getItem("local")
      ? JSON.parse(localStorage.getItem("local"))
      : {};

    if (this.$route.params.routeid) {
      this.tour.date = new Date().toISOString().split("T")[0];
      this.tour.job = this.stats.currentJob.id;
      this.tour.route = this.$route.params.routeid;
    } else if (this.$route.params.id) {
      if (
        this.local.tours &&
        this.local.tours.find((t) => t.id == this.$route.params.id)
      ) {
        this.tour = this.local.tours.find((t) => t.id == this.$route.params.id);
      } else {
        this.tour = this.stats.currentJob.tours.find(
          (t) => t.id == this.$route.params.id
        );
      }
      if (this.local.waypointscans) {
        this.waypointscans = this.tour.waypointscans.concat(
          this.local.waypointscans.filter((x) => x.tour == this.tour.id)
        );
      }
    }
    this.route = this.stats.currentJob.routes.find(
      (r) => r.id == this.tour.route
    );

    this.tour.date = this.tour.date.split("T")[0];
    this.checkIfWaypointsAreScanned();
    this.tryToSyncLocalUpdates();
    if (!window.rescanner) {
      let rescannerLib = document.createElement("script");
      rescannerLib.setAttribute("src", "/rescanner.js");
      rescannerLib.onload = () => {
        window.rescanner.onNFCSuccess = (message) => {
          vm.scanResult(message);
        };
        window.rescanner.onQRSuccess = (message) => {
          vm.scanResult(message);
        };
        window.rescanner.startNFCScan();
      };
      document.head.appendChild(rescannerLib);
    } else {
      window.rescanner.onNFCSuccess = (message) => {
        vm.scanResult(message);
      };
      window.rescanner.onQRSuccess = (message) => {
        console.log("scanresult called");
        vm.scanResult(message);
      };
      window.rescanner.startNFCScan();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
